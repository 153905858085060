.fluidbox {
  outline: none;
}

.fluidbox-overlay {
  cursor: pointer;
  cursor: -webkit-zoom-out;
  cursor: -moz-zoom-out;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: all .25s ease-in-out;
}

.fluidbox-wrap {
  background-position: center center;
  background-size: cover;
  margin: 0 auto;
  position: relative;
  transition: all .25s ease-in-out;
  text-align: center;
}

.fluidbox-ghost {
  background-size: cover;
  background-position: center center;
  position: absolute;
  z-index: 2000 !important;
  transition: all .25s ease-in-out;
}

.fluidbox-closed .fluidbox-ghost {
  -webkit-transition: top, left, opacity, -webkit-transform;
  -moz-transition: top, left, opacity, -moz-transform;
  -o-transition: top, left, opacity, -o-transform;
  transition: top, left, opacity, transform;
  transition-delay: 0, 0, .25s, 0;
}

.fluidbox-opened {
  cursor: pointer;
  cursor: -webkit-zoom-out !important;
  cursor: -moz-zoom-out !important;
}

.fluidbox-opened .fluidbox-overlay {
  z-index: 2000 !important;
  background: var(--navbar-background-color) !important;
}

.fluidbox-opened .fluidbox-wrap {
  z-index: 2001 !important;
}
