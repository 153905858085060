.atwho-view {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  margin-top: 18px;
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  box-shadow: 0 0 5px rgba(0,0,0,.1);
  min-width: 120px;
  z-index: 10
}
.atwho-view .cur {
  background: #317DDA;
  color: #fff
}
.atwho-view .cur small {
  color: #fff
}
.atwho-view strong { font-weight: normal; }

.atwho-view ul {
  list-style: none;
  padding: 0;
  margin: auto
}
.atwho-view ul li {
  display: block;
  padding: 5px 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer
}
.atwho-view ul li img { width: 16px; height: 16px; border-radius: 180px; }
.atwho-view small {
  font-size: smaller;
  color: #777;
  font-weight: 400
}
