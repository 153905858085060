@import "~homeland/_vars";
.subnav {
  margin-bottom: -18px;
  .nav-tabs {
    border-bottom: 0px;
    padding-left: 20px;
  }
  .nav-tabs > li > a:hover {
    border-color: transparent;
    background: none;
    text-decoration: underline;
  }

  .nav-tabs > .active > a,
  .nav-tabs > .active > a:hover {
    color: var(--btn-secondary-text-color);
    background: var(--card-background-color);
    border: 1px solid var(--card-border-color);
    border-bottom-color: transparent;
    cursor: default;
  }
}

.page-users {
  .nav-tabs {
    border-bottom: 0px;
  }
}

.node-topics {
  border-bottom: 1px solid var(--gray);
  tbody > tr > td {
    padding: 8px;
    color: var(--text-color1);
  }
  td.title {
    a:link,
    a:visited {
      font-size: 15px;
      text-decoration: none;
    }
    a:hover {
      text-decoration: underline;
    }
    em {
      font-style: normal;
      font-size: 12px;
      color: var(--text-color3);
    }
    i.icon {
      margin-bottom: -1px;
    }
  }
  td.node {
    a {
      color: var(--text-color1);
    }
  }
  tr.head {
    td {
      border-top: none;
      padding-top: 14px;
      color: var(--text-color3);
      font-weight: bold;
      font-size: 12px;
    }
  }
  tr.odd {
    td {
      background: var(--gray);
    }
  }
  tr.topic {
    td.author {
      width: 80px;
      a {
        color: var(--text-color1);
        font-weight: bold;
      }
    }
  }
}

.recent-topics {
  ul {
    li {
      .title {
        font-size: 14px;
      }
      i.icon {
        margin-bottom: -1px;
      }
      i.fa-pin {
        color: var(--text-color);
      }
      i.fa-award {
        color: var(--red);
      }
      .info {
        margin-top: 3px;
        font-size: 12px;
        color: var(--text-color3);
      }
      .node {
        margin-right: 5px;
        color: var(--text-color1);
        margin-right: 3px;
      }
    }
  }
}

.recent-replies {
  margin-bottom: 0;
  li {
    .title {
      font-size: 15px;
      .info {
        font-size: 14px;
        color: var(--secondary);
      }
    }
    .body {
      a {
        color: var(--primary);
      }
      margin-top: 6px;
      color: var(--secondary);
      p {
        font-size: 14px;
      }
      img {
        max-width: 680px;
      }
    }
  }
}

#main .userinfo h1 {
  text-align: left;
  display: inline;
}
.userinfo {
  .tagline {
    text-align: left;
    margin-top: -8px;
    margin-bottom: 20px;
  }
  .media-right {
    padding-left: 15px;
    text-align: center;
    .avatar {
      margin-bottom: 10px;
    }
  }
  .list-group {
    margin-bottom: 0px;
  }
  li {
    border-color: var(--gray);
    font-size: 13px;
    label {
      color: var(--text-color2);
      margin-right: 8px;
      display: inline-block;
      width: 80px;
      text-align: right;
    }
  }

  .user-bio {
    p:last-child {
      margin-bottom: 0;
    }
  }
}

.bio {
  font-size: 12px;
  line-height: 180%;
  p:last-child {
    margin-bottom: 0;
  }
}

.replies ul {
  margin: 0;
  h6 {
    color: var(--text-color2);
    font-weight: normal;
  }
  li {
    line-height: 180%;
    border-bottom: 1px solid var(--gray);
    list-style: none;
  }
  blockquote {
    line-height: 160%;
  }
}

.content > .tabs {
  border-bottom: 2px solid var(--gray);
  .active {
    margin-bottom: 0;
  }
}

table.node-topics {
  td {
    a {
      color: var(--text-color);
      font-weight: normal;
    }
    i.fa-award {
      color: var(--red);
    }
  }
  td.replied-at {
    width: 80px;
  }
}

.user-list {
  h2 {
    font-size: 14px;
    margin: 0;
  }
  .user {
    text-align: center;
    margin-bottom: 20px;
    overflow: hidden;
    .avatar {
      img {
        width: 48px;
        height: 48px;
        margin: 0 auto;
      }
      margin-bottom: 5px;
    }
    .name {
      a {
        color: var(--text-color);
      }
    }
  }
}

.user-list-tabs {
  .user-list-tab {
    @apply p-2 rounded text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-600 dark:hover:text-gray-400;

    &.active {
      @apply bg-gray-200 dark:bg-gray-700 dark:text-gray-400 text-gray-700;
    }
  }
}

.bloced-users {
  .item {
    text-align: left;
    margin-bottom: 10px;
    .media-object {
      display: inline;
    }
  }
}

.sidebar {
  .profile {
    .avatar {
      .media-object {
        width: 64px;
        height: 64px;
      }
      .level {
        margin-top: 6px;
        text-align: center;
      }
    }

    .name-box {
      text-decoration: none;

      .fullname {
        font-size: 18px;
        color: var(--text-color);
        font-weight: bold;
      }
      .login {
        font-size: 16px;
        color: var(--text-color1);
      }

      .user-badge {
        text-align: right;
        .opts {
          margin-top: 5px;
        }
      }
    }
    .item {
      margin-bottom: 5px;
      a {
        color: var(--text-color);
      }
      .fa {
        color: #aaa;
        margin-right: 6px;
      }
    }

    .number {
      font-size: 14px;
      color: var(--text-color2);
    }

    .counts {
      color: var(--text-color2);
      span {
        color: var(--text-color1);
      }
    }
    .follow-info {
      border-top: 1px solid var(--gray);
      text-align: center;
      margin-top: 15px;
      padding-top: 15px;
      a {
        display: block;
        text-decoration: none;
      }
      a.counter {
        font-size: 32px;
        line-height: 32px;
        color: var(--primary);
        &:hover {
          color: var(--primary);
        }
      }
      a.text {
        color: var(--text-color2);
      }
    }
    .buttons {
      border-top: 1px solid var(--gray);
      margin-top: 15px;
      padding-top: 15px;
    }
    .social {
      font-size: 24px;
      a:link,
      a:visited {
        text-decoration: none;
        .icon {
          color: var(--text-color1);
        }
      }
      a:hover {
        .icon {
          color: var(--text-color);
        }
      }

      a + a {
        margin-left: 20px;
      }
    }
    .tagline {
      border-top: 1px solid var(--gray);
      margin-top: 10px;
      color: var(--text-color2);
      line-height: 100%;
      padding: 10px;
      padding-bottom: 0;
    }
  }

  .user-teams {
    .media-object {
      display: inline-block;
      margin: 4px 2px;
    }
  }
}

.user-card {
  margin-bottom: 15px;
  padding-left: 15px;
  .media-heading {
    font-weight: bold;
    a {
      color: var(--text-color);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .infos {
    color: var(--text-color2);
    font-size: 12px;
    .tagline {
      width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .item {
      margin-top: 5px;
    }
  }
}

.user-profile-fields {
  .field {
    padding: 2px 0;
    label {
      color: var(--text-color1);
      display: inline-block;
      width: 100px;
      margin-right: 10px;
    }
    .value {
      a {
        text-decoration: underline;
      }
    }
  }
}

#user_github_repos {
  .more {
    text-align: right;
  }
  ul {
    margin: 0;
  }
  li {
    .title {
      position: relative;
      margin-bottom: 5px;
      a {
        color: var(--text-color);
        font-weight: bold;
      }
      .watchers {
        position: absolute;
        top: 2px;
        right: 0;
        color: var(--text-color2);
      }
    }
    .desc {
      font-size: 12px;
      color: var(--text-color2);
      padding: 0;
      margin: 0;
    }
  }
}

.user-activity-graph {
  overflow-x: scroll;
  text-align: center;
  svg {
    margin: 0 auto;
  }
}

.avatar-preview {
  .media-object {
    display: inline-block;
  }
}

.user-profile-card {
  text-align: left;
  .item {
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .name-box {
    text-decoration: none;

    .fullname {
      color: var(--text-color);
      font-weight: bold;
    }
    .login {
      color: var(--text-color1);
    }
  }

  .avatar-box {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .tagline {
    margin-bottom: 10px;
    line-height: 18px;
    max-height: 54px;
    color: var(--text-color2);
    // white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .company,
  .location {
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--text-color1);

    .fa {
      width: 18px;
    }
  }
  .social {
    font-size: 18px;

    a {
      text-decoration: none;
    }

    .icon {
      color: var(--text-color1);
    }

    a:hover {
      .icon {
        color: var(--text-color);
      }
    }
    a + a {
      margin-left: 10px;
    }
  }
  .buttons {
    margin-top: 10px;
    .btn {
      margin-top: 0;
    }
    .btn + .btn {
      margin-left: 8px;
    }
  }
}

.button-follow-user.btn-primary.active {
  background: var(--btn-secondary-active-background-color) !important;
  border-color: var(--btn-secondary-active-border-color) !important;
  color: var(--text-color1) !important;
}

.opts {
  .button-follow-user {
    &.active {
      background: var(--opts-link-hover-background-color);
      color: var(--text-color1) !important;
      .fa {
        color: var(--text-color1) !important;
      }
    }
  }
}

.page-settings {
  .themes {
    .form-check + .form-check {
      margin-left: 15px;
    }
  }

  .form-actions {
    .btn-primary {
      min-width: 150px;
    }
  }
}
